import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { EVMChainIds } from 'components/wallet/EthereumConnectors';
import { WalletBlockchain } from 'services/gql/generated';

export const MAINNET_ENVIRONMENTS = ['stg', 'prd'];

export type envType = 'dev' | 'stg' | 'prd';
export type BlockchainContextEnvironment = 'Development' | 'Staging' | 'Production';

export const getEnv = (): string => {
  return process.env.REACT_APP_NOTIFI_ENV ?? 'dev';
};

export const getEvmChainId = (walletBlockchain: WalletBlockchain): number => {
  if (!walletBlockchain) return;
  const getWalletBlockchain = EVMChainIds[walletBlockchain];
  const env = getEnv();

  if (env) {
    return getWalletBlockchain[env];
  }
  return getWalletBlockchain.dev;
};

export const checkEvmWalletChain = (
  chainId: number,
  walletBlockchain: WalletBlockchain,
): boolean => {
  const ethChain = getEvmChainId(walletBlockchain);
  if (ethChain === chainId) {
    return true;
  }
  return false;
};

export const getApiUrl = (): string => {
  const envValue = process.env.REACT_APP_NOTIFI_API_HOST;
  if (envValue !== undefined && envValue !== '') {
    return envValue;
  }

  return 'https://api.dev.notifi.network/gql';
};

export type EnvProps = { [key: string]: string };

export const getPathUrl = (): string | undefined => {
  const pathUrl: EnvProps = {
    dev: 'https://dev.notifi.network',
    localhost: 'http://localhost:3000',
    prd: 'https://notifi.network',
    stg: 'https://stg.notifi.network',
  };
  const env = getEnv();
  if (!env || window.location.host.includes('localhost')) {
    return pathUrl.localhost;
  }
  return pathUrl[env];
};

export const ensureNonLocalHostUrlPath = (): string => {
  const redirectUri = window.location.href;

  if (redirectUri.includes('localhost')) {
    return 'https://dev.notifi.network';
  }

  return getPathUrl();
};

export const getSubscriptionWsUrl = (): string | undefined => {
  const envValue = process.env.REACT_APP_NOTIFI_WS_HOST;
  if (envValue !== undefined && envValue !== '') {
    return envValue;
  }

  return undefined;
};

export const getGID = (): string => {
  return process.env.REACT_APP_LOGGING_GID ?? '';
};

export const getBlockchainExplorerLink = (
  addressOrSignature: string,
  chain: WalletBlockchain,
  type: 'wallet' | 'transaction',
): string | null => {
  switch (chain) {
    case WalletBlockchain.SOLANA: {
      const cluster = MAINNET_ENVIRONMENTS.includes(getEnv()) ? 'mainnet-beta' : 'devnet';
      return `https://solscan.io/${
        type === 'transaction' ? 'tx' : 'account'
      }/${addressOrSignature}?cluster=${cluster}`;
    }
    case WalletBlockchain.ETHEREUM: {
      const subDomain = MAINNET_ENVIRONMENTS.includes(getEnv()) ? '' : 'goerli.';
      const path = type === 'transaction' ? 'tx' : 'address';
      return `https://${subDomain}.etherscan.io/${path}/${addressOrSignature}`;
    }
    default:
      return null;
  }
};

export const getRPCLink = () => {
  if (MAINNET_ENVIRONMENTS.includes(getEnv())) {
    return 'https://notifin-mainnet-2014.mainnet.rpcpool.com/';
  }
  return 'https://devnet.genesysgo.net/';
};

export const getSolanaNetwork = () => {
  if (MAINNET_ENVIRONMENTS.includes(getEnv())) {
    return WalletAdapterNetwork.Mainnet;
  }

  return WalletAdapterNetwork.Devnet;
};

export const getEnvBlockchainContext = () => {
  const env = getEnv();
  const keyonEnv: EnvProps = {
    dev: 'Development',
    prd: 'Production',
    stg: 'Staging',
  };

  if (env) return keyonEnv[env] as BlockchainContextEnvironment;
  return keyonEnv.dev as BlockchainContextEnvironment;
};
