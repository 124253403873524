import { Container } from '@mui/material';
import Footer from 'components/footer';
import TopNav from 'components/top-nav';
import { white } from 'theme/Theme';

import EasySubscribeCustomerSection from './EasySubscribeCustomerSection';
import EasySubscribeFAQsSection from './EasySubscribeFAQsSection';
import EasySubscribeHeroSection from './EasySubscribeHeroSection';
import EasySubscribeSection from './EasySubscribeSection';
export const EasySubscriptionPage = () => {
  return (
    <Container disableGutters maxWidth={false} sx={{ background: white, position: 'relative' }}>
      <TopNav activeTab='Products' />
      <EasySubscribeHeroSection />
      <EasySubscribeSection />
      <EasySubscribeCustomerSection />
      <EasySubscribeFAQsSection />
      <Footer />
    </Container>
  );
};
