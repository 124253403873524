export const API_DOCS_URL = 'https://docs.notifi.network/docs';
export const FAQ_URL = 'https://docs.notifi.network/docs/faq';
export const DISCORD_URL = 'https://discord.gg/nAqR3mk3rv';
export const DISCORD_INTEGRATIONS_CHANNEL =
  'https://discord.com/channels/939658182509334538/950415885619843082';
export const EARLY_SIGN_UP_URL = 'https://tmc5razwnna.typeform.com/to/Q4PdipSH';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/notifinetwork';
export const LINKEDIN_JOBS_URL =
  'https://www.linkedin.com/company/notifinetwork/jobs/?viewAsMember=true';
export const MEDIUM_NEWS_URL = 'https://medium.com/notifi';
export const TWITTER_URL = 'https://twitter.com/NotifiNetwork';
export const GITHUB_URL = 'https://github.com/notifi-network';
export const LEAD_GEN_FORM =
  'https://docs.google.com/forms/d/e/1FAIpQLSeMPjorukiu71DmqErACfHNByOtJhI8QXoqtwbRVW7-WW-KQg/viewform';
export const APPLE_APP_LINK = 'https://apps.apple.com/us/app/notifi-hub/id6443833104';
export const ANDROID_APP_LINK =
  'https://play.google.com/store/apps/details?id=network.notifi.notifihub';
export const INTEGRATE_APP_CONFIG_URL = 'https://admin.notifi.network/';
export const DISCORD_INVITE_URL = 'https://discord.com/invite/nAqR3mk3rv';
export const ADMIN_PANEL_URL = 'https://admin.notifi.network/';
