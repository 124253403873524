import { Box, Button, Grid, SxProps, Typography } from '@mui/material';
import DropdownButton from 'components/buttons/DropdownButton';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cloud, dusk, eggshell, midnight } from 'theme/Theme';

import { ReactComponent as Vector } from '../../assets/Vector.svg';
import { ActiveTab } from './index';
import { MenuListDevelopers } from './MenuListDevelopers';
import { MenuListProducts } from './MenuListProducts';
import { MenuListResources } from './MenuListResources';
import { MenuListStudies } from './MenuListStudies';

type ButtonListProps = {
  activeTab?: ActiveTab;
  mobileMenu: boolean;
  isOpenMenu?: boolean;
  isStudiesOpen?: boolean;
  openResources?: boolean;
  openProducts?: boolean;
  openMenuItemMobile?: boolean;
  setOpenMenu?: (open: boolean) => void;
  handleToggle?: () => void;
  setMobileMenuOpen?: (open: boolean) => void;
  setOpenResources?: (open: boolean) => void;
  setOpenProducts?: (open: boolean) => void;
  setIsStudiesOpen?: (open: boolean) => void;
  handleToggleStudies?: () => void;
  setOpenMenuItemMobile?: (open: boolean) => void;
  handleToggleResources?: () => void;
  handleToggleProducts?: () => void;
};

type TopNavButtonProps = {
  isActive: boolean;
  onClick: () => void;
  sx?: SxProps;
};

const TopNavButton: React.FC<React.PropsWithChildren<TopNavButtonProps>> = ({
  children,
  isActive,
  onClick,
  sx,
}) => {
  return (
    <Grid item sx={{ position: 'relative' }}>
      <Button
        disableRipple
        onClick={onClick}
        sx={{
          '&::after': {
            background: 'linear-gradient(207.53deg, #FE7970 11.91%, #FEB776 88.99%)',
            bottom: '-8px',
            content: "''",
            display: { sm: isActive ? 'block' : 'none', xs: 'none' },
            height: '5px',
            left: 0,
            position: 'absolute',
            right: 0,
            width: '100%',
          },
          '&:hover': {
            background: eggshell,
          },
          background: 'transparent !important',
          borderRadius: 0,
          color: dusk,
          fontFamily: 'RotaExtraBold',
          fontSize: '16px',
          height: '100%',
          ml: { sm: 2, xs: 0 },
          my: { sm: 0, xs: '12px' },
          position: 'relative',
          ...sx,
        }}
      >
        {children}
      </Button>
    </Grid>
  );
};

export const TopNavButtonList: React.FC<ButtonListProps> = ({
  activeTab,
  handleToggle,
  handleToggleProducts,
  handleToggleResources,
  handleToggleStudies,
  isOpenMenu,
  isStudiesOpen,
  mobileMenu,
  openMenuItemMobile,
  openProducts,
  openResources,
  setIsStudiesOpen,
  setMobileMenuOpen,
  setOpenMenu,
  setOpenMenuItemMobile,
  setOpenProducts,
  setOpenResources,
}) => {
  const navigate = useNavigate();
  const [activeMenu, setActiveMenu] = useState<string>('');

  const handleOpenMenu = useCallback(
    (active: string) => {
      setActiveMenu(active);
      setOpenMenuItemMobile(!(active === activeMenu && openMenuItemMobile));
    },
    [activeMenu, openMenuItemMobile, setOpenMenuItemMobile],
  );

  return (
    <>
      {mobileMenu ? (
        <>
          <Grid alignItems='center' flexDirection='column' justifyContent='center'>
            <TopNavButton
              isActive={false}
              onClick={() => {
                setMobileMenuOpen(false);
                navigate('/');
              }}
              sx={{
                color: midnight,
                px: 8,
              }}
            >
              Home
            </TopNavButton>
          </Grid>
          <Grid
            alignItems='center'
            flexDirection='column'
            justifyContent='center'
            sx={{
              background: openMenuItemMobile && activeMenu === 'Products' ? cloud : eggshell,
              textAlign: 'center',
              width: '100%',
            }}
          >
            <TopNavButton
              isActive={false}
              onClick={() => handleOpenMenu('Products')}
              sx={{
                color: midnight,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                px: 8,
                width: '100%',
              }}
            >
              <div>Products</div> <Vector />
            </TopNavButton>
            <Box
              sx={{ display: openMenuItemMobile && activeMenu === 'Products' ? 'block' : 'none' }}
            >
              <MenuListProducts setOpen={setMobileMenuOpen} />
            </Box>
          </Grid>
          <Grid
            alignItems='center'
            flexDirection='column'
            justifyContent='center'
            sx={{
              background: openMenuItemMobile && activeMenu === 'Studies' ? cloud : eggshell,
              textAlign: 'center',
              width: '100%',
            }}
          >
            <TopNavButton
              isActive={false}
              onClick={() => handleOpenMenu('Studies')}
              sx={{
                color: midnight,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                px: 8,
                width: '100%',
              }}
            >
              <div>Case Studies</div> <Vector />
            </TopNavButton>
            <Box
              sx={{ display: openMenuItemMobile && activeMenu === 'Studies' ? 'block' : 'none' }}
            >
              <MenuListStudies setOpen={setMobileMenuOpen} />
            </Box>
          </Grid>

          <Grid
            alignItems='center'
            flexDirection='column'
            justifyContent='center'
            sx={{
              background: openMenuItemMobile && activeMenu === 'Developers' ? cloud : eggshell,
              textAlign: 'center',
              width: '100%',
            }}
          >
            <TopNavButton
              isActive={false}
              onClick={() => handleOpenMenu('Developers')}
              sx={{
                color: midnight,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                px: 8,
                width: '100%',
              }}
            >
              <div>Developers</div> <Vector />
            </TopNavButton>
            <Box
              sx={{ display: openMenuItemMobile && activeMenu === 'Developers' ? 'block' : 'none' }}
            >
              <MenuListDevelopers setOpen={setMobileMenuOpen} />
            </Box>
          </Grid>
          <Grid
            alignItems='center'
            flexDirection='column'
            justifyContent='center'
            sx={{
              background: openMenuItemMobile && activeMenu === 'Resources' ? cloud : eggshell,
              pb: { xs: '30px' },
              textAlign: 'center',
              width: '100%',
            }}
          >
            <TopNavButton
              isActive={false}
              onClick={() => handleOpenMenu('Resources')}
              sx={{
                color: '#262949',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                px: 8,
                width: '100%',
              }}
            >
              <div>Resources</div> <Vector />
            </TopNavButton>
            <Box
              sx={{ display: openMenuItemMobile && activeMenu === 'Resources' ? 'block' : 'none' }}
            >
              <MenuListResources setOpen={setMobileMenuOpen} />
            </Box>
          </Grid>
        </>
      ) : null}
      {mobileMenu
        ? null
        : openProducts != undefined &&
          setOpenProducts != undefined &&
          handleToggleProducts != undefined && (
          <DropdownButton
            childrenList={<MenuListProducts setOpen={setOpenProducts} />}
            open={openProducts}
            setOpen={setOpenProducts}
          >
            <TopNavButton
              isActive={activeTab === 'Products'}
              onClick={() => {
                handleToggleProducts();
                setIsStudiesOpen(false);
                setOpenResources(false);
                setOpenMenu(false);
              }}
            >
              <Typography
                sx={{
                  color: openProducts || activeTab === 'Products' ? midnight : dusk,
                  fontFamily: 'RotaExtraBold',
                  fontSize: '16px',
                }}
              >
                Products
              </Typography>
              <Vector style={{ marginLeft: '6px' }} />
            </TopNavButton>
          </DropdownButton>
        )}
      {mobileMenu
        ? null
        : isStudiesOpen != undefined &&
          isStudiesOpen != undefined &&
          handleToggleStudies != undefined && (
          <DropdownButton
            childrenList={<MenuListStudies setOpen={setIsStudiesOpen} />}
            open={isStudiesOpen}
            setOpen={setIsStudiesOpen}
          >
            <TopNavButton
              isActive={activeTab === 'CaseStudies'}
              onClick={() => {
                handleToggleStudies();
                setOpenMenu(false);
                setOpenResources(false);
                setOpenProducts(false);
              }}
            >
              <span
                style={{
                  color: isStudiesOpen || activeTab === 'CaseStudies' ? midnight : 'inherit',
                }}
              >
                Case Studies <Vector style={{ marginLeft: '6px' }} />
              </span>
            </TopNavButton>
          </DropdownButton>
        )}
      {mobileMenu
        ? null
        : open != undefined &&
          setOpenMenu != undefined &&
          handleToggle != undefined && (
          <DropdownButton
            childrenList={<MenuListDevelopers setOpen={setOpenMenu} />}
            open={isOpenMenu}
            setOpen={setOpenMenu}
          >
            <TopNavButton
              isActive={activeTab === 'Developers'}
              onClick={() => {
                handleToggle();
                setIsStudiesOpen(false);
                setOpenResources(false);
                setOpenProducts(false);
              }}
            >
              <Typography
                sx={{
                  color: isOpenMenu || activeTab === 'Developers' ? midnight : dusk,
                  fontFamily: 'RotaExtraBold',
                  fontSize: '16px',
                }}
              >
                Developers
              </Typography>
              <Vector style={{ marginLeft: '6px' }} />
            </TopNavButton>
          </DropdownButton>
        )}
      {mobileMenu
        ? null
        : openResources != undefined &&
          setOpenResources != undefined &&
          handleToggleResources != undefined && (
          <DropdownButton
            childrenList={<MenuListResources setOpen={setOpenResources} />}
            open={openResources}
            setOpen={setOpenResources}
          >
            <TopNavButton
              isActive={activeTab === 'Resources'}
              onClick={() => {
                handleToggleResources();
                setOpenMenu(false);
                setIsStudiesOpen(false);
                setOpenProducts(false);
              }}
            >
              <span
                style={{
                  color: openResources || activeTab === 'Resources' ? midnight : 'inherit',
                }}
              >
                Resources <Vector style={{ marginLeft: '6px' }} />
              </span>
            </TopNavButton>
          </DropdownButton>
        )}

      {/* <Grid
        alignItems='center'
        flexDirection='column'
        justifyContent='center'
        sx={{ '& button': { textTransform: 'lowercase' } }}
      >
        <TopNavButton
          isActive={activeTab === 'xyz'}
          onClick={() => {
            setMobileMenuOpen(false);
          }}
        >
          xyz
        </TopNavButton>
      </Grid> */}
    </>
  );
};
