import { Container, Stack } from '@mui/material';
import { DAPP_LOOKUP } from 'constants/dappLookup';
import { white } from 'theme/Theme';
import { BrandLogo, TextWithBottomBorder } from 'views/marketing/MarketingComponents';

const defaultDappIconStyle = {
  height: { sm: 35 },
  width: { sm: 192, xs: 135 },
};

const dappList = [
  {
    dappName: 'plume',
    style: defaultDappIconStyle,
  },
  {
    dappName: 'og',
    style: defaultDappIconStyle,
  },
  {
    dappName: 'arch',
    style: defaultDappIconStyle,
  },
  {
    dappName: 'movement',
    style: {
      ...defaultDappIconStyle,
      ml: 4,
      mr: 4,
    },
  },
];

const EasySubscribeCustomerSection = () => {
  return (
    <Container
      maxWidth={false}
      sx={{
        background: white,
        pb: { sm: 10, xs: '27px' },
        pt: 8,
        px: { sm: 2, xs: 0 },
      }}
    >
      <Stack
        alignItems='center'
        justifyContent='space-evenly'
        sx={{ margin: 'auto', maxWidth: 1250 }}
      >
        <TextWithBottomBorder
          label='Notifi customers using Easy Subscribe'
          typographySx={{ fontSize: '22px' }}
        />
        <Stack
          alignItems='center'
          direction='row'
          flexWrap='wrap'
          gap='24px 16px'
          justifyContent='center'
          sx={{ mt: '40px' }}
        >
          {dappList.map(({ dappName, style }) => {
            const { logoSrc, name, url } = DAPP_LOOKUP[dappName];
            return (
              <BrandLogo
                altText={name}
                imgSrc={logoSrc}
                key={name}
                onClick={() => window.open(url, '_blank')}
                sx={{
                  ...style,
                  cursor: 'pointer',
                }}
              />
            );
          })}
        </Stack>
      </Stack>
    </Container>
  );
};

export default EasySubscribeCustomerSection;
