import useQueryParam from 'hooks/useQueryParam';
import { useEffect } from 'react';

const RedirectPage: React.FC = () => {
  const target = useQueryParam('target');

  useEffect(() => {
    window.location.replace(target);
  }, [target]);

  return null;
};

export default RedirectPage;
