import { Link, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  ADMIN_PANEL_URL,
  API_DOCS_URL,
  DISCORD_URL,
  FAQ_URL,
  GITHUB_URL,
  LINKEDIN_URL,
  MEDIUM_NEWS_URL,
  TWITTER_URL,
} from 'constants/urls';
import { eggshell, heather } from 'theme/Theme';

import { ReactComponent as Logo } from '../../assets/notifi-logo.svg';

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const imgWidth = isMobile ? 126 : 148;

  return (
    <Stack
      alignItems='center'
      sx={{
        backgroundColor: '#1C1F3E',
        position: 'relative',
        width: '100%',
        zIndex: 102,
      }}
    >
      <Stack
        direction={{ lg: 'row', xs: 'column' }}
        sx={{
          gap: '40px',
          position: 'relative',
          px: { lg: '6vw', xl: '8vw', xs: '40px' },
          py: { sm: 7, xs: 5 },
          width: '100%',
          zIndex: 102,
        }}
      >
        <Stack>
          <Link href='/main'>
            <Logo width={imgWidth} />
          </Link>
          <Typography
            sx={{
              color: heather,
              fontFamily: 'RotaExtraBold',
              fontSize: { sm: '20px', xs: '18px' },
              fontWeight: 700,
              lineHeight: 'normal',
              mt: '23px',
            }}
          >
            Powering unrivaled notification experiences in web3
          </Typography>
          <Stack direction='row' mt='11px'>
            <Typography
              sx={{
                color: '#828AE3',
                fontFamily: 'RotaExtraBold',
                fontSize: { sm: '20px', xs: '16px' },
                fontWeight: 700,
                lineHeight: 'normal',
              }}
            >
              Have any questions?
            </Typography>
            <Typography
              onClick={() => window.open(FAQ_URL, '_blank')}
              sx={{
                color: '#828AE3',
                cursor: 'pointer',
                fontFamily: 'RotaExtraBold',
                fontSize: { sm: '20px', xs: '16px' },
                fontWeight: 700,
                lineHeight: 'normal',
                ml: '5px',
              }}
            >
              Check out our FAQs
            </Typography>
          </Stack>

          {isMobile ? null : (
            <Stack direction='row' gap='40px' mt='33px'>
              <Typography
                sx={{
                  color: heather,
                  fontFamily: 'RotaExtraBold',
                  fontSize: '14px',
                  fontWeight: 800,
                  lineHeight: 'normal',
                }}
              >
                © 2024 Notifi Network, Inc
              </Typography>
              <Link
                href='/terms'
                sx={{
                  color: heather,
                  fontFamily: 'RotaExtraBold',
                  fontSize: '14px',
                  fontWeight: 800,
                  lineHeight: 'normal',
                  textDecoration: 'underline',
                }}
              >
                Terms of Service
              </Link>

              <Link
                href='/privacy'
                sx={{
                  color: heather,
                  fontFamily: 'RotaExtraBold',
                  fontSize: '14px',
                  fontWeight: 800,
                  lineHeight: 'normal',
                  textDecoration: 'underline',
                }}
              >
                Privacy Policy
              </Link>
            </Stack>
          )}
        </Stack>

        <Stack
          direction='row'
          sx={{
            flex: 1,
            flexWrap: { lg: 'nowrap', xs: 'wrap' },
            gap: { xl: '80px', xs: '50px' },
            justifyContent: { lg: 'flex-end', xs: 'flex-start' },
            mb: { sm: 0, xs: 3 },
            mt: 0,
            mx: { xl: 5, xs: 0 },
          }}
        >
          <Stack sx={{ gap: '16px' }}>
            <Typography
              sx={{
                color: heather,
                fontFamily: 'RotaExtraBold',
                fontSize: '14px',
                fontWeight: 800,
              }}
            >
              Products
            </Typography>

            <Link
              href='/monitoring'
              sx={{
                color: eggshell,
                fontSize: '14px',
                fontWeight: 800,
                lineHeight: 'normal',
              }}
            >
              Monitoring Service
            </Link>
            <Link
              href='/eventing-service'
              sx={{
                color: eggshell,
                fontSize: '14px',
                fontWeight: 800,
                lineHeight: 'normal',
              }}
            >
              Eventing Service
            </Link>
            <Link
              href='/notification-service'
              sx={{
                color: eggshell,
                fontSize: '14px',
                fontWeight: 800,
                lineHeight: 'normal',
              }}
            >
              Notification Service
            </Link>
            <Link
              href='/notifi-card'
              sx={{
                color: eggshell,
                fontSize: '14px',
                fontWeight: 800,
                lineHeight: 'normal',
              }}
            >
              Notification Card
            </Link>
            <Link
              href='/community-manager'
              sx={{
                color: eggshell,
                fontSize: '14px',
                fontWeight: 800,
                lineHeight: 'normal',
              }}
            >
              Community Manager
            </Link>
          </Stack>

          <Stack sx={{ gap: '16px' }}>
            <Typography
              sx={{
                color: heather,
                fontFamily: 'RotaExtraBold',
                fontSize: '14px',
                fontWeight: 800,
              }}
            >
              Developers
            </Typography>

            <Link
              href={API_DOCS_URL}
              sx={{
                color: eggshell,
                fontSize: '14px',
                fontWeight: 800,
                lineHeight: 'normal',
              }}
              target='_blank'
            >
              Docs
            </Link>
            <Link
              href={GITHUB_URL}
              sx={{
                color: eggshell,
                fontSize: '14px',
                fontWeight: 800,
                lineHeight: 'normal',
              }}
              target='_blank'
            >
              GitHub
            </Link>
            <Link
              href={ADMIN_PANEL_URL}
              sx={{
                color: eggshell,
                fontSize: '14px',
                fontWeight: 800,
                lineHeight: 'normal',
              }}
              target='_blank'
            >
              Notifi Admin Portal
            </Link>
          </Stack>

          <Stack sx={{ gap: '16px' }}>
            <Typography
              sx={{
                color: heather,
                fontFamily: 'RotaExtraBold',
                fontSize: '14px',
                fontWeight: 800,
              }}
            >
              Company
            </Typography>

            <Link
              href='/about'
              sx={{
                color: eggshell,
                fontSize: '14px',
                fontWeight: 800,
                lineHeight: 'normal',
              }}
            >
              About
            </Link>
            <Link
              href='/blog'
              sx={{
                color: eggshell,
                fontSize: '14px',
                fontWeight: 800,
                lineHeight: 'normal',
              }}
            >
              Blog
            </Link>
            <Link
              href='/presskit'
              sx={{
                color: eggshell,
                fontSize: '14px',
                fontWeight: 800,
                lineHeight: 'normal',
              }}
            >
              Brand Kit
            </Link>
          </Stack>

          {isMobile ? null : (
            <Stack sx={{ gap: '16px' }}>
              <Typography
                sx={{
                  color: heather,
                  fontFamily: 'RotaExtraBold',
                  fontSize: '14px',
                  fontWeight: 800,
                }}
              >
                Contact
              </Typography>

              {/* <Link
              href='/privacy'
              sx={{
                color: eggshell,
                fontFamily: 'RotaExtraBold',
                fontSize: '14px',
                fontWeight: 800,
                lineHeight: 'normal',
              }}
            >
              Sales
            </Link> */}
              <Link
                href='mailto:sales@notifi.network'
                sx={{
                  color: eggshell,
                  fontSize: '14px',
                  fontWeight: 800,
                  lineHeight: 'normal',
                }}
              >
                Email
              </Link>
            </Stack>
          )}

          <Stack sx={{ gap: '16px' }}>
            <Typography
              sx={{
                color: heather,
                fontFamily: 'RotaExtraBold',
                fontSize: '14px',
                fontWeight: 800,
              }}
            >
              Follow Us
            </Typography>

            <Link
              href={TWITTER_URL}
              sx={{
                color: eggshell,
                fontSize: '14px',
                fontWeight: 800,
                lineHeight: 'normal',
              }}
              target='_blank'
            >
              Twitter
            </Link>
            <Link
              href={LINKEDIN_URL}
              sx={{
                color: eggshell,
                fontSize: '14px',
                fontWeight: 800,
                lineHeight: 'normal',
              }}
              target='_blank'
            >
              LinkedIn
            </Link>
            <Link
              href={MEDIUM_NEWS_URL}
              sx={{
                color: eggshell,
                fontSize: '14px',
                fontWeight: 800,
                lineHeight: 'normal',
              }}
              target='_blank'
            >
              Medium
            </Link>
            <Link
              href={DISCORD_URL}
              sx={{
                color: eggshell,
                fontSize: '14px',
                fontWeight: 800,
                lineHeight: 'normal',
              }}
              target='_blank'
            >
              Discord
            </Link>
          </Stack>

          {isMobile ? (
            <Stack sx={{ gap: '16px' }}>
              <Typography
                sx={{
                  color: heather,
                  fontFamily: 'RotaExtraBold',
                  fontSize: '14px',
                  fontWeight: 800,
                }}
              >
                Contact
              </Typography>

              {/* <Link
              href='/privacy'
              sx={{
                color: eggshell,
                fontFamily: 'RotaExtraBold',
                fontSize: '14px',
                fontWeight: 800,
                lineHeight: 'normal',
              }}
            >
              Sales
            </Link> */}
              <Link
                href='mailto:sales@notifi.network'
                sx={{
                  color: eggshell,
                  fontSize: '14px',
                  fontWeight: 800,
                  lineHeight: 'normal',
                }}
              >
                Email
              </Link>
            </Stack>
          ) : null}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Footer;
