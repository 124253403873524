enum Action {
  LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS',
  LOGOUT_USER = 'LOGOUT_USER',

  SET_VERIFIED_TARGET_EMAIL = 'SET_VERIFIED_TARGET_EMAIL',

  STAGE_ALERT_DATA = 'STAGE_ALERT_DATA',
  SET_ALERT_DATA = 'SET_ALERT_DATA',
  SET_ALERT_MODAL_STEP = 'SET_ALERT_MODAL_STEP',
  RESET_STAGED_ALERT_DATA = 'RESET_STAGED_ALERT_DATA',

  STORE_ACTIVE_ALERT_DATA = 'STORE_ACTIVE_ALERT_DATA',
  LOGIN_USER_FROM_DAPP_SUCCESS = 'LOGIN_USER_FROM_DAPP_SUCCESS',

  SET_ETH_TOOLTIP_STEP = 'SET_ETH_TOOLTIP_STEP',

  SET_AVATAR_INDEX = 'SET_AVATAR_INDEX',
}

export default Action;
