import { Alert } from 'services/AlertService';
import Action from 'store/actions/types';

export type DataState = {
  active: AlertsData;
};

export type AlertsData = {
  alerts: Alert[];
};

const initialState: DataState = {
  active: {
    alerts: [],
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function dataReducer(state: DataState = initialState, action: any) {
  switch (action.type) {
    case Action.STORE_ACTIVE_ALERT_DATA: {
      const alerts: Alert[] = action.payload;
      const nextActive = {
        alerts: alerts,
      };

      return {
        ...state,
        active: nextActive,
      };
    }
    default:
      return state;
  }
}
