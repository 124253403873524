import { combineReducers, configureStore } from '@reduxjs/toolkit';

import alertReducer from './store/reducers/alertReducer';
import dashboardReducer from './store/reducers/dashboardReducer';
import dataReducer from './store/reducers/dataReducer';
import loginReducer from './store/reducers/loginReducer';

const combinedReducers = combineReducers({
  alert: alertReducer,
  dashboard: dashboardReducer,
  data: dataReducer,
  user: loginReducer,
});

const store = configureStore({
  reducer: combinedReducers,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
