import AppStyle from 'AppStyle';
import { ApolloContextProvider } from 'contexts/ApolloContextProvider';
import React from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import Router from 'routes';

import * as serviceWorker from './serviceWorker';
import store from './store';

const tagManagerArgs = {
  gtmId: 'GTM-KN26W29',
};

TagManager.initialize(tagManagerArgs);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <ApolloContextProvider>
      <Provider store={store}>
        <AppStyle>
          <Router />
        </AppStyle>
      </Provider>
    </ApolloContextProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
