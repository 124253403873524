import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionProps,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  Button,
  Container,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { ADMIN_PANEL_URL } from 'constants/urls';
import { useState } from 'react';
import { midnight, ochreGradient, white } from 'theme/Theme';

import { ReactComponent as ChevronIcon } from '../../assets/chevron-icn.svg';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion elevation={0} square {...props} />
))(() => ({
  '&::before': {
    display: 'none',
  },
  '&:first-of-type': {
    marginTop: 0,
  },
  background: 'transparent',
  marginBottom: '0 !important',
  marginTop: '16px',
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ChevronIcon />} {...props} />
))(() => ({
  '& .MuiAccordionSummary-content': {
    margin: '0 !important',
  },
  background: white,
  borderBottom: 0,
  borderRadius: '8px',
  boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
  cursor: 'pointer',
  justifyContent: 'space-between',
  margin: 0,
  padding: '34px 36px 34px 45px',
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  background: ochreGradient,
  border: '0',
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px',
  padding: '40px 45px',
  transition: 'height 0.25s ease-in-out',
}));

interface Pannel {
  title: string;
  content: React.ReactNode;
  value: string;
}

interface Props {
  icon?: React.ReactNode;
  pannels: Pannel[];
}

const FAQsComponents: React.FC<Props> = ({ icon, pannels }) => {
  const [expanded, setExpanded] = useState<string | false>('');

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  if (!icon) {
    icon = <ChevronIcon />;
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        maxWidth: 1100,
        px: { sm: 2, xs: 0 },
      }}
    >
      <Stack>
        <Typography
          sx={{
            color: midnight,
            fontFamily: 'RotaExtraBold',
            fontSize: '32px',
            mb: '40px',
            textAlign: 'center',
          }}
        >
          FAQs
        </Typography>

        {pannels.map(pannel => (
          <Accordion
            expanded={expanded === pannel.value}
            key={pannel.value}
            onChange={handleChange(pannel.value)}
          >
            <AccordionSummary expandIcon={icon}>
              <Typography sx={{ color: midnight, fontFamily: 'RotaBold', fontSize: '22px' }}>
                {pannel.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>{pannel.content}</AccordionDetails>
          </Accordion>
        ))}

        <Stack
          direction='row'
          sx={{
            alignItems: 'center',
            gap: '32px',
            justifyContent: 'center',
            mt: '45px',
            width: '100%',
          }}
        >
          <Button
            onClick={() => window.open(ADMIN_PANEL_URL, '_blank')}
            size='small'
            sx={{
              background: midnight,
              borderRadius: '50px',
              color: white,
              fontFamily: 'RotaBold',
              py: '15px',
              width: 130,
            }}
            variant='contained'
          >
            Start Now
          </Button>
          {/* remove contact sales button for first release */}
          {/* <Button
            size='small'
            sx={{
              '&:hover': { color: white },
              background: white,
              borderRadius: '50px',
              color: midnight,
              fontFamily: 'RotaBold',
              py: '15px',
              width: 130,
            }}
            variant='contained'
          >
            Contact Sales
          </Button> */}
        </Stack>
      </Stack>
    </Container>
  );
};

export default FAQsComponents;
