import { Alert, AlertColor, AlertProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CustomContentProps, useSnackbar } from 'notistack';
import React, { forwardRef, ReactNode } from 'react';

import { dusk } from '../../theme/Theme';

const StyledAlert = styled(Alert)<AlertProps>(({ severity, theme }) => ({
  borderRadius: '15px',
  minHeight: '52px',

  ...(severity === 'error' && {
    '& svg': {
      color: theme.palette.info.main,
    },
    backgroundColor: dusk,
    color: theme.palette.info.main,
    position: 'relative',
    top: '0px',
    width: 425,
  }),
}));

const NotistackSnackbarComp = forwardRef<HTMLDivElement, CustomContentProps & { component: ReactNode }>(
  (props, forwardedRef) => {
    const { closeSnackbar } = useSnackbar();
    const { component, id, message, variant } = props;

    return (
      <StyledAlert
        onClose={() => closeSnackbar(id)}
        ref={forwardedRef}
        severity={variant as AlertColor}
      >
        {component ? component : message}
      </StyledAlert>
    );
  },
);

NotistackSnackbarComp.displayName = 'NotistackSnackbarComp';

export const NotistackSnackbar = React.memo(NotistackSnackbarComp);
