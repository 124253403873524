import { ReactGAImplementation } from 'react-ga4';
import { InitOptions } from 'react-ga4/types/ga4';
import { getGID } from 'utils/envUtils';

const GA_TRACKING_ID = getGID();
const gaInitOptions = [{ trackingId: GA_TRACKING_ID }];

// Event Name must be <= 40 char
export enum EventName {
  ENQUEUE_ERROR = 'enqueue_error',
  GRAPHQL_ERROR = 'graphql_error',
  NETWORK_ERROR = 'network_error',
  DATA_DISCREPANCY = 'data_discrepancy',
}

// Event Params are limited to 25 keys
// keys <= 40 char length
// values <= 100 char length
export type EventParams = {
  [key: string]: string;
};

class Logger extends ReactGAImplementation {
  userId: string | null = null;

  constructor(initOptions: InitOptions[]) {
    super();
    this.initialize(initOptions);
  }

  setUserId(id: string | null) {
    if (this.userId == null && id != null) {
      this.set({ userId: id });
      this.userId = id;
    }
  }

  logEvent(eventName: EventName, params: EventParams, err?: unknown) {
    if (err instanceof Error) {
      this.event(eventName, { ...params, error_message: err.message });
    } else if (typeof err === 'string') {
      this.event(eventName, { ...params, error_message: err });
    } else {
      this.event(eventName, params);
    }
  }
}

export default new Logger(gaInitOptions);
