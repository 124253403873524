import { Box, Grid, MenuList, Stack, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { cloud, coral, dusk, eggshell, midnight, theme } from 'theme/Theme';

import { ReactComponent as IconCommunityManager } from '../../assets/icn-community.svg';
import { ReactComponent as IconEasySubscribe } from '../../assets/icn-easysub.svg';
import { ReactComponent as IconEventingService } from '../../assets/icn-eventing.svg';
import { ReactComponent as IconFoundationAlert } from '../../assets/icn-foundation.svg';
import { ReactComponent as MonitoringService } from '../../assets/icn-monitoring.svg';
import { ReactComponent as IconNotificationCard } from '../../assets/icn-notification.svg';
import { ReactComponent as IconNotificationService } from '../../assets/icn-notificationservice.svg';
import { ReactComponent as IconWalletAlert } from '../../assets/icn-wallet.svg';

type Props = {
  setOpen: (open: boolean) => void;
};

const PRODUCTS_MENUS = [
  {
    desc: 'Embedded notification card customized to your UI',
    href: '/notifi-card',
    icon: <IconNotificationCard width={'24px'} />,
    name: 'Notification Card',
  },
  {
    desc: 'Alerts delivered natively into web3 wallets',
    href: '/wallet-alerts',
    icon: <IconWalletAlert width={'24px'} />,
    name: 'Wallet Alerts',
  },
  {
    desc: 'Robust notification inbox for foundations',
    href: '/foundation-alerts',
    icon: <IconFoundationAlert width={'24px'} />,
    name: 'Foundation Alerts',
  },
  {
    desc: 'Marketing solution to engage your community',
    href: '/community-manager',
    icon: <IconCommunityManager width={'24px'} />,
    name: 'Community Manager',
  },
  {
    desc: 'One-click alert subscription',
    href: '/easy-subscribe',
    icon: <IconEasySubscribe width={'24px'} />,
    name: 'Easy Subscribe',
  },
];

const SERVICES_MENUS = [
  {
    desc: 'Monitor and index blockchain activity',
    href: '/monitoring',
    icon: <MonitoringService width={'24px'} />,
    name: 'Monitoring Service',
  },
  {
    desc: 'Message delivery across all destinations',
    href: '/notification-service',
    icon: <IconNotificationService width={'24px'} />,
    name: 'Notification Service',
  },
  {
    desc: 'Manage alert events across sources',
    href: '/eventing-service',
    icon: <IconEventingService width={'24px'} />,
    name: 'Eventing Service',
  },
];

export const MenuListProducts: React.FC<React.PropsWithChildren<Props>> = ({ setOpen }) => {
  const navigate = useNavigate();
  const handleNavigate = (href: string) => {
    setOpen(false);
    navigate(href);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MenuList
      sx={{
        borderRadius: '12px',
        flexWrap: 'wrap',
        fontFamily: 'RotaExtraBold',
        fontSize: '16px',
        pb: { sm: 0, xs: 2 },
        py: 0,
        width: { sm: '1000px', xs: 'unset' },
      }}
    >
      <Grid
        borderRadius={'12px'}
        container
        sx={{ flexDirection: { sm: 'row', xs: 'column' }, width: '100%' }}
      >
        <Grid
          item
          sx={{
            pb: { sm: 2, xs: 0 },
            pt: { sm: 2, xs: 0 },
            px: { sm: 2, xs: 0 },
            py: { sm: 2, xs: 0 },
            width: '100%',
          }}
          xs={isMobile ? 12 : 6}
        >
          <Typography
            color={midnight}
            fontFamily={'RotaExtraBold'}
            fontSize={16}
            fontWeight='800'
            sx={{
              borderBottom: `2px solid ${cloud}`,
              display: { sm: 'block', xs: 'none' },
              lineHeight: '35.183px',
              padding: '4px 10px',
            }}
          >
            Notifi Products
          </Typography>
          <Grid
            container
            sx={{
              columnGap: '12px',
              paddingTop: { sm: 1.5, xs: 0 },
              rowGap: '4px',
              width: '100%',
            }}
          >
            {PRODUCTS_MENUS.map(menu => (
              <Grid
                item
                key={menu.name}
                onClick={() => handleNavigate(menu.href)}
                sm={5.76}
                sx={{
                  '&:hover': {
                    '.vector-icon': {
                      color: coral,
                    },
                    backgroundColor: eggshell,
                    color: dusk,
                  },
                  borderRadius: '8px',
                  cursor: 'pointer',
                  justifyContent: { sm: 'flex-start', xs: 'center' },
                  mt: 0,
                  paddingTop: 1,
                  px: { sm: 0, xs: 8 },
                }}
                xs={12}
              >
                <Stack
                  alignItems={'center'}
                  direction='row'
                  flex={1}
                  fontFamily={'Rota'}
                  sx={{ gap: '6px', padding: '0 4px 6px 0' }}
                >
                  <Box display={'flex'} justifyContent={'start'} width={'16%'}>
                    {menu.icon}
                  </Box>
                  <Box flexWrap={'wrap'} gap={2} width={'84%'}>
                    <Stack
                      alignItems={'center'}
                      direction={'row'}
                      sx={{
                        justifyContent: {
                          marginBottom: { sm: '4px', xs: '0' },
                          sm: 'flex-start',
                          xs: 'start',
                        },
                      }}
                    >
                      <Typography
                        color={midnight}
                        sx={{
                          fontFamily: isMobile ? 'RotaBold' : 'RotaExtraBold',
                          fontSize: isMobile ? '16px' : '18px',
                          fontWeight: isMobile ? '700' : '800',
                        }}
                      >
                        {menu.name}
                      </Typography>
                      <Box
                        className='vector-icon'
                        component='svg'
                        fill='none'
                        height='8px'
                        sx={{
                          color: '#B6B8D5',
                          display: { sm: 'block', xs: 'none' },
                          marginLeft: '6px',
                          transition: 'color 0.3s',
                        }}
                        viewBox='0 0 6 8'
                        width='8px'
                      >
                        <path
                          d='M1 1.5L4 4L1 6.5'
                          stroke='currentColor'
                          strokeLinecap='round'
                          strokeWidth='1.5'
                        />
                      </Box>
                    </Stack>
                    <Typography
                      color={dusk}
                      fontFamily={'RotaSemiBold'}
                      fontSize={14}
                      sx={{ display: { sm: 'block', xs: 'none' } }}
                      variant='subtitle2'
                    >
                      {menu.desc}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            // backgroundColor: { sm: '#FAFBFD', xl: '#FAFBFD', xs: cloud },
            borderBottomLeftRadius: '12px',
            borderBottomRightRadius: '12px',
            pt: { sm: 2, xs: 0 },
            px: { sm: 2, xs: 0 },
            py: { sm: 2, xs: 0 },
            width: '100%',
          }}
          xs={isMobile ? 12 : 6}
        >
          <Typography
            color={midnight}
            fontFamily={'RotaExtraBold'}
            fontSize={16}
            fontWeight='800'
            sx={{
              borderBottom: `2px solid ${cloud}`,
              display: { sm: 'block', xs: 'none' },
              letterSpacing: '1.5px',
              lineHeight: '35.183px',
              padding: '4px 10px',
            }}
          >
            Notifi Services
          </Typography>
          <Grid
            container
            sx={{ columnGap: '12px', paddingTop: { sm: 1.5, xs: 0.5 }, rowGap: '4px' }}
          >
            {SERVICES_MENUS.map(menu => (
              <Grid
                item
                key={menu.name}
                onClick={() => handleNavigate(menu.href)}
                sm={5.76}
                sx={{
                  '&:hover': {
                    '.vector-icon': {
                      color: coral,
                    },
                    backgroundColor: eggshell,
                    color: dusk,
                  },
                  borderRadius: '8px',
                  cursor: 'pointer',
                  justifyContent: { sm: 'flex-start', xs: 'center' },
                  mt: 0,
                  paddingTop: 1,
                  px: { sm: 0, xs: 8 },
                }}
                xs={12}
              >
                <Stack
                  alignItems={'center'}
                  direction='row'
                  flex={1}
                  fontFamily={'Rota'}
                  sx={{ gap: '6px', padding: '0 4px 6px 0' }}
                >
                  <Box display={'flex'} justifyContent={'start'} width={'16%'}>
                    {menu.icon}
                  </Box>
                  <Box flexWrap={'wrap'} gap={2} width={'84%'}>
                    <Stack
                      alignItems={'center'}
                      direction={'row'}
                      sx={{
                        justifyContent: { sm: 'flex-start', xs: 'start' },
                      }}
                    >
                      <Typography
                        color={midnight}
                        sx={{
                          fontFamily: isMobile ? 'RotaBold' : 'RotaExtraBold',
                          fontSize: isMobile ? '16px' : '18px',
                          fontWeight: isMobile ? '700' : '800',
                        }}
                      >
                        {menu.name}
                      </Typography>
                      <Box
                        className='vector-icon'
                        component='svg'
                        fill='none'
                        height='8px'
                        sx={{
                          color: '#B6B8D5',
                          display: { sm: 'block', xs: 'none' },
                          marginLeft: '6px',
                          transition: 'color 0.3s',
                        }}
                        viewBox='0 0 6 8'
                        width='8px'
                      >
                        <path
                          d='M1 1.5L4 4L1 6.5'
                          stroke='currentColor'
                          strokeLinecap='round'
                          strokeWidth='1.5'
                        />
                      </Box>
                    </Stack>
                    <Typography
                      color={dusk}
                      fontFamily={'RotaSemiBold'}
                      fontSize={14}
                      fontWeight='600'
                      sx={{ display: { sm: 'block', xs: 'none' } }}
                      variant='subtitle2'
                    >
                      {menu.desc}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </MenuList>
  );
};
