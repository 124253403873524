import { UserAttributes } from 'contexts/FeatureFlagsContext';
import { RoleType } from 'hooks/auth/useLoginStateReducer';
import localforage from 'localforage';

localforage.config({
  name: 'notifi',
});

export type Authorization = Readonly<{
  token: string;
  expiry: string;
  roles: RoleType[];
}>;

export type UserSettings = Readonly<{
  detailedAlertHistoryEnabled: boolean;
  userHasChatEnabled: boolean;
}>;

export type VisitedPages = Readonly<{
  dappSubscriptions: boolean | null;
  chat: boolean | null;
}>;

export type UnstoppableDomainsUserInfo = Readonly<{
  account: string | undefined | null;
  domainName: string | null;
  token: string | null;
}>;

export type AvatarId = Readonly<string>;

type sdkStorage = {
  authorization: Authorization | null;
  jwtPrefix?: string;
  walletPublicKey?: string;
};

export type Storage = Readonly<{
  getAuthorization: () => Promise<Authorization | null>;
  setAuthorization: ({
    authorization,
    jwtPrefix,
    walletPublicKey,
  }: sdkStorage | null) => Promise<void>;
  getUserSettings: () => Promise<UserSettings | null>;
  setUserSettings: (settings: UserSettings | null) => Promise<void>;
  getUserAttributes: () => Promise<UserAttributes | null>;
  setUserAttributes: (value: UserAttributes | null) => Promise<void>;
  getVisitedPages: () => Promise<VisitedPages | null>;
  setVisitedPages: (value: VisitedPages | null) => Promise<void>;
  getAvatarId: () => Promise<AvatarId | null>;
  setAvatarId: (value: AvatarId | null) => Promise<void>;
  getUnstoppableDomainsUserInfo: () => Promise<UnstoppableDomainsUserInfo | null>;
  setUnstoppableDomainsUserInfo: (value: UnstoppableDomainsUserInfo | null) => Promise<void>;
}>;

const storage = (env: string) => {
  const dappAddress = 'notifi';
  const baseKey = `${env}:${dappAddress}:`;
  const authorizationKey = baseKey + 'authorization';
  const settingsKey = baseKey + 'settings';
  const userAttributesKey = baseKey + 'userAttributes';
  const visitedPagesKey = baseKey + 'visitedPages';
  const avatarIdKey = baseKey + 'avatarId';
  const unstoppableDomainsUserInfoKey = baseKey + 'UnstoppableDomainsUserInfo';

  const getAuthorization = async (): Promise<Authorization | null> => {
    return await localforage.getItem<Authorization>(authorizationKey);
  };

  const setAuthorization = async ({
    authorization,
    jwtPrefix,
    walletPublicKey,
  }: sdkStorage): Promise<void> => {
    const newKey = `${jwtPrefix}:${dappAddress}:${walletPublicKey}:authorization`;

    if (jwtPrefix && walletPublicKey) {
      await localforage.setItem(newKey, {
        expiry: authorization.expiry,
        token: authorization.token,
      });
    }
    await localforage.setItem(authorizationKey, authorization);
  };

  const getUserSettings = async (): Promise<UserSettings | null> => {
    return await localforage.getItem<UserSettings>(settingsKey);
  };

  const setUserSettings = async (settings: UserSettings | null): Promise<void> => {
    await localforage.setItem(settingsKey, settings);
  };

  const getUserAttributes = async (): Promise<UserAttributes | null> => {
    return await localforage.getItem<UserAttributes>(userAttributesKey);
  };

  const setUserAttributes = async (settings: UserAttributes | null): Promise<void> => {
    await localforage.setItem(userAttributesKey, settings);
  };

  const getVisitedPages = async (): Promise<VisitedPages | null> => {
    return await localforage.getItem<VisitedPages>(visitedPagesKey);
  };

  const setVisitedPages = async (value: VisitedPages | null): Promise<void> => {
    await localforage.setItem(visitedPagesKey, value);
  };

  const getAvatarId = async (): Promise<AvatarId | null> => {
    return await localforage.getItem<AvatarId>(avatarIdKey);
  };

  const setAvatarId = async (value: AvatarId | null): Promise<void> => {
    await localforage.setItem(avatarIdKey, value);
  };

  const getUnstoppableDomainsUserInfo = async (): Promise<UnstoppableDomainsUserInfo | null> => {
    return await localforage.getItem<UnstoppableDomainsUserInfo>(unstoppableDomainsUserInfoKey);
  };

  const setUnstoppableDomainsUserInfo = async (
    value: UnstoppableDomainsUserInfo | null,
  ): Promise<void> => {
    await localforage.setItem(unstoppableDomainsUserInfoKey, value);
  };

  return {
    getAuthorization,
    getAvatarId,
    getUnstoppableDomainsUserInfo,
    getUserAttributes,
    getUserSettings,
    getVisitedPages,
    setAuthorization,
    setAvatarId,
    setUnstoppableDomainsUserInfo,
    setUserAttributes,
    setUserSettings,
    setVisitedPages,
  };
};

export default storage;
