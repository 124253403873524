import { Alert } from 'services/AlertService';
import { Filter } from 'services/FilterService';
import { NewTargetInput, TargetInput } from 'services/TargetService';
import { NewWalletInput, Wallet } from 'services/WalletService';

import Action from '../actions/types';

export enum CreateAlertStep {
  CREATE_BUNDLE = 0,
  ALERT_SETTINGS = 1,
  TARGET_DESTINATIONS = 2,
}

export type StagedCreateAlertInput = {
  applicableFilters?: Filter[];
  daoSources?: Wallet[];
  newWalletInputs?: NewWalletInput[];
  filterIds?: string[];
  filterOptions?: string;
  existingWallets?: Wallet[];
  walletsToDelete?: Wallet[];
  selectedSubscriptionTypes?: string[];
  newTargetInputs?: TargetInput[];
  existingTargets?: TargetInput[];
  targetsToDelete?: TargetInput[];
  existingAlerts?: Alert[];
};

export type CreateAlertInput = {
  name: string;
  sourceGroupId: string;
  filterId: string;
  filterOptions?: string;
  targetGroupId: string;
  emailTargetIds: string[];
  discordTargetIds: string[];
  smsTargetIds: string[];
  telegramTargetIds: string[];
  newEmailValues: NewTargetInput[];
  newSmsValues: NewTargetInput[];
  newTelegramValues: NewTargetInput[];
  newDiscordIds: string[];
  newDiscordValues: NewTargetInput[];
};

export type PartialCreateAlertInput = Partial<CreateAlertInput>;

type State = {
  create: CreateAlertInput;
  alertModalStep: CreateAlertStep;
  stage: StagedCreateAlertInput;
};

const initialState: State = {
  alertModalStep: CreateAlertStep.ALERT_SETTINGS,
  create: {
    discordTargetIds: [],
    emailTargetIds: [],
    filterId: '',
    filterOptions: undefined,
    name: '',
    newDiscordIds: [],
    newDiscordValues: [],
    newEmailValues: [],
    newSmsValues: [],
    newTelegramValues: [],
    smsTargetIds: [],
    sourceGroupId: '',
    targetGroupId: '',
    telegramTargetIds: [],
  },
  stage: {
    applicableFilters: [],
    daoSources: [],
    existingAlerts: [],
    existingTargets: [],
    existingWallets: [],
    filterIds: [],
    filterOptions: undefined,
    newTargetInputs: [],
    newWalletInputs: [],
    selectedSubscriptionTypes: [],
    targetsToDelete: [],
    walletsToDelete: [],
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function alertReducer(state: State = initialState, action: any) {
  switch (action.type) {
    case Action.STAGE_ALERT_DATA:
      return {
        ...state,
        stage: {
          ...state.stage,
          ...action.payload,
        },
      };
    case Action.SET_ALERT_DATA:
      return {
        ...state,
        create: {
          ...state.create,
          ...action.payload,
        },
      };
    case Action.SET_ALERT_MODAL_STEP:
      return {
        ...state,
        alertModalStep: action.payload,
      };
    case Action.RESET_STAGED_ALERT_DATA:
      return initialState;
    default:
      return state;
  }
}
