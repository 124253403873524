import { ApolloProvider } from '@apollo/client';
import React, { useContext, useMemo, useState } from 'react';
import createClient from 'services/apollo-client';
export type ContextType = {
  setToken: (token: string | null) => void;
};

const Context = React.createContext<ContextType>(undefined as unknown as ContextType);

export const ApolloContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const [token, setToken] = useState<string | null>(null);
  const client = useMemo(() => createClient(token), [token]);
  return (
    <Context.Provider value={{ setToken }}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </Context.Provider>
  );
};

export const useApolloContextProvider = (): ContextType => {
  return useContext(Context);
};
