import { ButtonGroup, ClickAwayListener, Grow, Paper, Popper } from '@mui/material';
import React from 'react';
import { white } from 'theme/Theme';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  childrenList?: React.ReactNode;
};

const DropdownButton: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  childrenList,
  open,
  setOpen,
}) => {
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup aria-label='split button' ref={anchorRef} variant='text'>
        {children}
      </ButtonGroup>
      <ClickAwayListener onClickAway={handleClose}>
        <Popper
          anchorEl={anchorRef.current}
          disablePortal
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [-25, 0],
              },
            },
          ]}
          open={open}
          placement="bottom-start"
          style={{ zIndex: 1 }}
          transition
        >
          {({ placement, TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom-start' ? 'center top' : 'center bottom',
              }}
            >
              <Paper sx={{ backgroundColor: white, borderRadius: '12px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.03)', margin: '14px 20px' }}>
                <>{childrenList}</>
              </Paper>
            </Grow>
          )}
        </Popper>
      </ClickAwayListener>
    </>
  );
};

export default DropdownButton;
