import { Container, Link, Typography } from '@mui/material';
import { eggshell, midnight } from 'theme/Theme';
import FAQsComponents from 'views/marketing/FAQsComponents';

const FAQs = [
  {
    content: (
      <Typography sx={{ color: midnight, fontFamily: 'RotaSemiBold', fontSize: '22px' }}>
        Create an account on{' '}
        <Link
          href='https://admin.notifi.network/'
          sx={{ fontFamily: 'RotaSemiBold' }}
          target='_blank'
        >
          Notifi Admin
        </Link>{' '}
        and see the Easy Subscribe{' '}
        <Link
          href='https://docs.notifi.network/docs/alert-subscribe/easy-subscribe'
          sx={{ fontFamily: 'RotaSemiBold' }}
          target='_blank'
        >
          {' '}
          implementation guide.
        </Link>
      </Typography>
    ),
    title: 'How do I get started and implement Easy Subscribe?',
    value: 'panel1',
  },
];

const EasySubscribeFAQsSection = () => {
  return (
    <Container
      maxWidth={false}
      sx={{
        background: eggshell,
        px: 2,
        py: { sm: 9, xs: '40px' },
      }}
    >
      <FAQsComponents pannels={FAQs} />
    </Container>
  );
};

export default EasySubscribeFAQsSection;
