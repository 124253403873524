import Action from '../actions/types';

type VerifiedTargetEmailState = {
  verifiedTargetEmail: string;
};

type SetVerifiedTargetEmailPayload = {
  verifiedTargetEmail: string;
};

type SetVerifiedTargetAction = {
  type: Action.SET_VERIFIED_TARGET_EMAIL
  payload: SetVerifiedTargetEmailPayload
};

type DashboardAction = SetVerifiedTargetAction;

const initialState: VerifiedTargetEmailState = {
  verifiedTargetEmail: '',
};

export default function dashboardReducer(
  state: VerifiedTargetEmailState = initialState,
  action: DashboardAction,
) {
  switch (action.type) {
    case Action.SET_VERIFIED_TARGET_EMAIL: {
      const { verifiedTargetEmail } = action.payload;
      return {
        ...state,
        verifiedTargetEmail,
      };
    }
    default:
      return state;
  }
}