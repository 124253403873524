import './fonts/NeueMontreal/NeueMontreal-Bold.ttf';
import './fonts/NeueMontreal/NeueMontreal-Italic.ttf';
import './fonts/NeueMontreal/NeueMontreal-Regular.ttf';
import './fonts/Rota/Rota-Bold.ttf';
import './fonts/Rota/Rota-Italic.ttf';
import './fonts/Rota/Rota-Regular.ttf';
import './index.css';

import { CssBaseline } from '@mui/material';
import React from 'react';

import ThemeProvider from './theme/Theme';

const AppStyle: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <>
    <CssBaseline />
    <ThemeProvider>
      <div className='App'>{children}</div>
    </ThemeProvider>
  </>
);

export default AppStyle;
