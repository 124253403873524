import {  createTheme, Palette, ThemeProvider } from '@mui/material/styles';
import React from 'react';

declare module '@mui/material/styles' {
  interface Theme {
    palette: Palette;
  }
}
/* eslint-disable @typescript-eslint/no-unused-vars */
export const coral = '#EA7E68',
             cloud = '#E8EBF5',
             solanaPurple = '#9945FF',
             white = '#FFFFFF',
             eggshell = '#F5F6FB',
             dusk = '#80829D',
             ghostwhite = '#f5f6fb',
             gold = '#ECA656',
             heather = '#B6B8D5',
             lilac = '#D2B9DC',
             lavender = '#EEDAFC',
             lavenderGray = '#B6B8D5',
             mauve = '#C093D1',
             mutedMidnight = '#353860',
             midnight = '#262949',
             mutedPurple = '#D2B9DC',
             magenta = '#C595FF',
             rose = '#C78186',
             tangerine = '#FE9C76',
             error = '#EE5331',
             warning = '#F1CB68',
             success = '#13AD88',
             borderGradient = 'linear-gradient(272.12deg, #FEB776 1.08%, #DB9EFF 91.37%)',
             copperGradient = 'linear-gradient(207.53deg, #FE7970 11.91%, #FEB776 88.99%)',
             lilacGradient = 'linear-gradient(180deg, #D2B9DC -6.75%, rgba(240, 236, 246, 0.03) 100%)',
             linearGradient = 'linear-gradient(24.04deg, #EA7E68 16.87%, rgba(210, 185, 220, 0.06) 84.4%);',
             opalGradient =
    'conic-gradient(from -36.51deg at 49.95% 50%, #F5F6FB 0deg, #F4F4F8 39.01deg, #F2D3CD 161.73deg, #E6DDEF 268.53deg, #F5F6FB 335.86deg, #F5F6FB 360deg)',
             whiteGradient = 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0))',
             peachyGradient = 'linear-gradient(262.12deg, #EEDAFC 3.08%, #FFF8F1 97.43%)',
             icyGradient = 'linear-gradient(96.46deg, #E8E8FF 1.19%, #FFFFFF 99.82%)',
             blueGradient = 'linear-gradient(258.28deg, #D8DFFF 19.58%, #EEF1FE 81.44%)',
             sunsetGradient='linear-gradient(246.39deg, #F8D8C4 -2.52%, #BDBCFF 81.43%)',
             icyGradient2 = 'linear-gradient(103deg, #E5E6FC 3.27%, #FFF 101.89%)',
             ochreGradient = 'linear-gradient(262deg, #FAF4FF 3.08%, #FFF6EB 97.43%)';
/* eslint-enable @typescript-eslint/no-unused-vars */

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    tablet: true;
  }
}
const defaultTheme = createTheme();
export const theme = createTheme({
  breakpoints: {
    values: {
      ...defaultTheme.breakpoints.values,
      tablet: 1024,
    },
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: lavender,
            border: '2px solid transparent',
            borderColor: lilac,
          },
          backgroundColor: white,
          cursor: 'pointer',
          height: 51,
          width: 51,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          '&.Mui-disabled': {
            backgroundColor: coral,
            color: '#FFFFFF',
          },
          '&:active': {
            backgroundColor: tangerine,
          },
          '&:hover': {
            backgroundColor: coral,
            boxShadow: '0px 2px 0px #CB5841',
          },
          backgroundColor: coral,
          boxShadow: 'unset',
          color: '#FFFFFF',
          fontFamily: 'RotaSemiBold',
          fontWeight: 700,
        },
        outlined: {
          '&:active': {
            border: 0,
          },
          '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: `0px 0px 2px 1px ${coral}`,
          },
          border: `1px solid ${coral}`,
          color: coral,
          fontFamily: 'Rota',
          fontWeight: 700,
        },
        root: {
          '&.Mui-disabled': {
            opacity: 0.4,
          },
          borderRadius: '4px',
          boxSizing: 'border-box',
          fontFamily: 'Rota',
          fontSize: 16,
          fontWeight: 500,
          lineHeight: '19.2px',
          paddingBottom: '10.5px',
          paddingEnd: '10.5px',
          paddingStart: '10.5px',
          paddingTop: '10.5px',
          textTransform: 'capitalize',
        },
        text: {
          '&:active': {
            backgroundColor: lavender,
          },
          '&:hover': {
            backgroundColor: 'rgba(238, 218, 252, 0.5)',
          },
          color: coral,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'NeueMontreal';
          font-style: normal;
          font-display: swap;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          '&.MuiTypography-root.MuiTypography-h6': {
            backgroundColor: '#FFF',
          },
          color: midnight,
          fontFamily: 'RotaExtraBold',
          fontSize: '16pt',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          backgroundColor: eggshell,
          borderRadius: '4px',
          color: midnight,
          fontFamily: 'NeueMontreal',
          fontSize: 16,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: eggshell,
          borderRadius: '4px',
          color: midnight,
          fontFamily: 'NeueMontreal',
          fontSize: 16,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: dusk,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: 'Rota',
          fontWeight: 700,
          textDecoration: 'none',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          background: '#FFF',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
          backgroundColor: eggshell,
          borderRadius: '4px',
          color: midnight,
          fontFamily: 'NeueMontreal',
          fontSize: 16,
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        rectangular: {
          borderRadius: '6px',
          height: '20px',
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          height: 16,
          padding: 0,
          width: 36,
        },
        switchBase: {
          '&.Mui-checked': {
            '& + .MuiSwitch-track': {
              background: copperGradient,
              border: 0,
              opacity: 1,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
            color: '#fff',
            transform: 'translateX(19px)',
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
          },
          margin: 2,
          padding: 0,
          transitionDuration: '160ms',
        },
        thumb: {
          boxSizing: 'border-box',
          color: white,
          height: 14,
          marginTop: -1,
          width: 14,
        },
        track: {
          backgroundColor: heather,
          borderRadius: 30,
          opacity: 1,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: midnight,
          },
          color: dusk,
          fontFamily: 'Rota',
          fontSize: 18,
          fontWeight: 700,
          textTransform: 'none',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          color: midnight,
        },
        head: {
          color: dusk,
          paddingBottom: '4px',
          verticalAlign: 'bottom',
        },
        root: {
          fontFamily: 'Rota',
          fontWeight: 700,
          lineHeight: '17.36px',
          paddingBottom: 0,
          paddingTop: 0,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: '54px',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          background: copperGradient,
          borderRadius: '30px',
          height: '4px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            width: '5px',
          },
          '&::-webkit-scrollbar:vertical, & *::-webkit-scrollbar-thumb': {
            background: cloud,
            borderRadius: '30px',
            width: '5px',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          color: dusk,
          fontFamily: 'NeueMontreal',
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 'normal',
        },
        body2: {
          fontFamily: 'NeueMontreal',
          lineHeight: 'normal',
        },
        button: {
          fontFamily: 'NeueMontreal',
        },
        caption: {
          fontFamily: 'NeueMontreal',
        },
        h1: {
          color: midnight,
          fontFamily: 'RotaExtraBold',
          fontSize: 50,
          fontWeight: 800,
        },
        h2: {
          color: 'white',
          fontFamily: 'Rota',
          fontSize: 40,
          fontWeight: 800,
        },
        h3: {
          color: 'white',
          fontFamily: 'Rota',
          fontSize: 40,
          fontWeight: 800,
        },
        h4: {
          color: heather,
          fontFamily: 'Rota',
          fontSize: 26,
          fontWeight: 800,
        },
        h5: {
          color: dusk,
          fontFamily: 'NeueMontreal',
          fontSize: 22,
        },
        root: {
          fontFamily: 'NeueMontreal',
        },
        subtitle1: {
          color: midnight,
          fontFamily: 'RotaExtraBold',
          fontSize: 22,
        },
        subtitle2: {
          color: dusk,
          fontFamily: 'RotaExtraBold',
          fontSize: 18,
        },
      },
    },
  },
  palette: {
    background: {
      default: lavender,
    },
    error: {
      main: coral,
    },
    info: {
      main: '#FFF',
    },
    primary: {
      main: coral,
    },
    secondary: {
      main: heather,
    },
    success: {
      main: success,
    },
    warning: {
      main: warning,
    },
  },
  typography: {
    fontFamily: 'NeueMontreal, Raleway, Arial',
  },
});

interface NotifiThemeOption {
  children: React.ReactNode | React.ReactNode[];
}

const NotifiThemeProvider = (props: NotifiThemeOption) => (
  <ThemeProvider theme={theme}>{props?.children}</ThemeProvider>
);

export default NotifiThemeProvider;
