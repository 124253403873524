import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletBlockchain } from 'services/gql/generated';
import { envType } from 'utils/envUtils';

export type EVMChains =
  | WalletBlockchain.POLYGON
  | WalletBlockchain.ETHEREUM
  | WalletBlockchain.ARBITRUM
  | WalletBlockchain.BINANCE;

// We can add support for any EVM compatible chain here
// Look up chainId here: https://chainlist.org/

export const supportedEvmChainIdsArray = [1, 11155111, 5, 56, 97, 137, 42161, 80001, 421613] as const;

export type EVMChainNumbers = typeof supportedEvmChainIdsArray[number];

export type chainToChainNumbers = Record<envType, EVMChainNumbers>;

export const EVMChainIds: Record<EVMChains, chainToChainNumbers> = {
  [WalletBlockchain.ETHEREUM]: {
    dev: 11155111, //sepolia
    prd: 1,
    stg: 1,
  },
  [WalletBlockchain.POLYGON]: {
    dev: 80001, //mumbai
    prd: 137,
    stg: 137,
  },
  [WalletBlockchain.ARBITRUM]: {
    dev: 421613, //arbitrum goerli
    prd: 42161,
    stg: 42161,
  },
  [WalletBlockchain.BINANCE]: {
    dev: 97,
    prd: 56,
    stg: 56,
  },
};

const RPC_URLS: Record<EVMChainNumbers, string> = {
  1: 'https://rpc.ankr.com/eth',
  11155111: 'https://sepolia.infura.io/v3/',
  137: 'https://rpc.ankr.com/polygon',
  42161: 'https://rpc.ankr.com/arbitrum',
  421613: 'https://goerli-rollup.arbitrum.io/rpc',
  5: 'https://rpc.ankr.com/eth_goerli',
  56: 'https://rpc.ankr.com/bsc',
  80001: 'https://rpc.ankr.com/polygon_mumbai',
  97: 'https://rpc.ankr.com/bsc_testnet_chapel',
};

export const injected = new InjectedConnector({
  supportedChainIds: [...supportedEvmChainIdsArray],
});

export const walletconnect = new WalletConnectConnector({
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  qrcodeModalOptions: {
    desktopLinks: [],
    mobileLinks: [],
  },
  //add empty object and arrays to only show QR code because desktop wallet connect has major bugs on WC's end
  rpc: RPC_URLS,
  supportedChainIds: [...supportedEvmChainIdsArray],
});
