import FishIcon1 from '../../assets/chat/fish-icn-1.svg';
import FishIcon2 from '../../assets/chat/fish-icn-2.svg';
import FishIcon3 from '../../assets/chat/fish-icn-3.svg';
import FishIcon4 from '../../assets/chat/fish-icn-4.svg';
import FishIcon5 from '../../assets/chat/fish-icn-5.svg';
import FishIcon6 from '../../assets/chat/fish-icn-6.svg';
import FishIcon7 from '../../assets/chat/fish-icn-7.svg';
import FishIcon8 from '../../assets/chat/fish-icn-8.svg';
import FishIcon9 from '../../assets/chat/fish-icn-9.svg';

export type AvatarData = {
  icon: string;
  iconName: string;
};

type AvatarMap = {
  [id: string]: AvatarData;
};

export enum ChatType {
  PRIVATE = 'PRIVATE',
  BLOCKCHAIN = 'BLOCKCHAIN',
}

export const defaultFishId = '0';
export const avatarData: AvatarMap = {
  '0': {
    icon: FishIcon8,
    iconName: 'FishIcon8',
  },
  '1': { icon: FishIcon7, iconName: 'FishIcon7' },
  '2': {
    icon: FishIcon3,
    iconName: 'FishIcon3',
  },

  '3': {
    icon: FishIcon4,
    iconName: 'FishIcon4',
  },
  '4': {
    icon: FishIcon5,
    iconName: 'FishIcon5',
  },
  '5': {
    icon: FishIcon9,
    iconName: 'FishIcon9',
  },
  '6': { icon: FishIcon1, iconName: 'FishIcon1' },
  '7': { icon: FishIcon6, iconName: 'FishIcon6' },
  '8': {
    icon: FishIcon2,
    iconName: 'FishIcon2',
  },
};

export const SNOOZE_AT_KEY = 'snoozedAt';
export const SNOOZE_PERIOD = 5000;
