import { ApolloLink, from, HttpLink, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import Logger, { EventName } from 'logger/Logger';
import { getApiUrl, getSubscriptionWsUrl } from 'utils/envUtils';
import { v4 as uuid } from 'uuid';

export type SetLinkParameters = Readonly<{
  token: string | null;
}>;

const X_REQUEST_ID_HEADER = 'x-request-id';

// Reference: https://www.apollographql.com/docs/react/api/link/introduction/
export const createApolloLink = ({ token }: SetLinkParameters): ApolloLink => {
  const authLink: ApolloLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token === null ? undefined : `Bearer ${token}`,
      },
    };
  });

  const requestIdLink: ApolloLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        [X_REQUEST_ID_HEADER]: uuid(),
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const context = operation.getContext();
    const requestId = context.headers[X_REQUEST_ID_HEADER];
    const operationName = operation.operationName;

    if (graphQLErrors) {
      graphQLErrors.forEach(({ extensions, message }) => {
        Logger.logEvent(EventName.GRAPHQL_ERROR, {
          error_code: extensions?.code + '' ?? 'undefined',
          error_message: message,
          operationName,
          requestId,
        });
      });
    }

    if (networkError) {
      Logger.logEvent(EventName.NETWORK_ERROR, {
        error_message: networkError.message,
        error_name: networkError.name,
        operationName,
        requestId,
      });
    }
  });

  const httpLink: ApolloLink = new HttpLink({
    fetch: (url, init) => {
      if (typeof init?.body !== 'string') {
        return fetch(url, init);
      }
      const operationName = encodeURIComponent(JSON.parse(init.body).operationName ?? '');
      return fetch(`${url}/${operationName}`, init);
    },
    fetchOptions: 'no-cors',
    uri: getApiUrl(),
  });

  const wsUrl = getSubscriptionWsUrl();
  if (wsUrl == null) {
    return from([authLink, requestIdLink, errorLink, httpLink]);
  }

  // TODO: use graphql-ws when uspported by HotChocolate
  const wsLink = new WebSocketLink({
    options: {
      connectionParams: {
        Authorization: token == null ? undefined : `Bearer ${token}`,
      },
      reconnect: token != null,
    },
    uri: wsUrl,
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink,
  );

  return from([authLink, requestIdLink, errorLink, splitLink]);
};
