import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Card,
  CardActions,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
} from '@mui/material';
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dusk, eggshell, midnight, white } from 'theme/Theme';

import { SNOOZE_AT_KEY, SNOOZE_PERIOD } from '../../views/chat/constants';

const styles = {
  actionButton: {
    marginBottom: '20px',
    marginTop: '10px',
    minWidth: '135px',
  },
  actionRoot: {
    justifyContent: 'space-between',
    padding: '20px 22px 5px',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  button: {
    padding: 0,
    textTransform: 'none',
  },
  card: {
    backgroundColor: '#ffffff',
    borderRadius: '20px',
    width: '384px',
  },
  checkIcon: {
    fontSize: 20,
    paddingRight: 4,
  },
  expand: {
    color: dusk,
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    transition: 'all .2s',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  icons: {
    marginLeft: 'auto',
  },
  message: {
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    color: midnight,
    display: '-webkit-box',
    fontFamily: 'Rota',
    fontSize: '14px',
    fontWeight: '600',
    marginBottom: '10px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  paper: {
    backgroundColor: white,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  progressBar: {
    backgroundColor: eggshell,
    height: '6px',
  },
  root: {
    '@media (min-width:600px)': {
      minWidth: '344px !important',
    },
    marginTop: '91px',
    maxWidth: '400px',
  },
  titles: {
    display: 'flex',
  },
  typography: {
    color: dusk,
    fontFamily: 'RotaBold',
    fontSize: '18px',
    fontWeight: 700,
  },
  userId: {
    color: midnight,
    fontFamily: 'RotaBold',
    fontSize: '18px',
    marginLeft: '3px',
  },
};

interface XMSNotificationProps extends CustomContentProps {
  userId: string;
  conversationId: string;
}

const XMSNotification = forwardRef<HTMLDivElement, XMSNotificationProps>(
  ({ id, ...props }, ref) => {
    const { closeSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [progress, setProgress] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        if (progress >= 0 && progress <= 100) {
          setProgress(prev => prev + (100 / SNOOZE_PERIOD) * 100);
        }
      }, 100);

      return () => {
        clearInterval(interval);
      };
    }, [progress]);

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    const handleViewXMS = useCallback(() => {
      navigate(`/chat/${props.conversationId}`);
      closeSnackbar(id);
    }, [id, closeSnackbar, navigate, props.conversationId]);

    const handleSnooze = useCallback(() => {
      window.localStorage.setItem(SNOOZE_AT_KEY, new Date().toString());
      closeSnackbar(id);
    }, [closeSnackbar, id]);

    return (
      <SnackbarContent ref={ref} style={styles.root}>
        <Card sx={styles.card}>
          <CardActions sx={{ ':root': styles.actionRoot }}>
            <Box component='div' sx={styles.titles}>
              <Typography sx={styles.typography} variant='body2'>
                xMS from
              </Typography>
              <Typography sx={styles.userId} variant='body2'>
                {props.userId}
              </Typography>
            </Box>
            <Box component='div' sx={styles.icons}>
              <IconButton onClick={handleDismiss} size='medium' sx={styles.expand}>
                <CloseIcon fontSize='medium' />
              </IconButton>
            </Box>
          </CardActions>
          <Paper
            sx={() => ({
              backgroundColor: white,
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: '24px',
              paddingRight: '24px',
            })}
          >
            <Box
              sx={() => ({
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '2',
                color: midnight,
                display: '-webkit-box',
                fontFamily: 'Rota',
                fontSize: '14px',
                fontWeight: '600',
                marginBottom: '10px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              })}
            >
              {props.message}
            </Box>
            <Box
              sx={() => ({
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
              })}
            >
              <Button onClick={handleSnooze} sx={styles.actionButton} variant='outlined'>
                5 Min Snooze
              </Button>
              <Button onClick={handleViewXMS} sx={styles.actionButton} variant='contained'>
                View xMS
              </Button>
            </Box>
          </Paper>
          <LinearProgress sx={styles.progressBar} value={100 - progress} variant='determinate' />
        </Card>
      </SnackbarContent>
    );
  },
);

XMSNotification.displayName = 'XMSNotification';

export default XMSNotification;
