import { ApolloClient, DefaultOptions, InMemoryCache } from '@apollo/client';
import { NotificationHistoryConnection } from 'services/gql/generated';
import { isEmpty } from 'utils/stringUtils';

import { createApolloLink } from './createApolloLink';

const defaultOptions: DefaultOptions = {
  query: {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  },
  watchQuery: {
    errorPolicy: 'ignore',
    fetchPolicy: 'cache-first',
  },
};

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        notificationHistory: {
          keyArgs: args => {
            const categories: string[] = [...(args?.filter?.categories ?? [])].filter(
              category => !isEmpty(category),
            );
            if (categories.length === 0) {
              return 'ALL';
            }

            return Array.from(new Set(categories).values()).sort().join('|');
          },
          merge: (
            current: NotificationHistoryConnection,
            incoming: NotificationHistoryConnection,
            { readField, variables },
          ) => {
            if (current?.edges == null) {
              return incoming;
            }
            if (incoming?.edges == null) {
              return current;
            }

            const edgesByCursor = new Map(current.edges.map(edge => [edge.cursor, edge]));
            incoming.edges.forEach(edge => {
              edgesByCursor.set(edge.cursor, edge);
            });

            // ensure collection is sorted by date descending
            const edges = Array.from(edgesByCursor.values()).sort((a, b) => {
              const createdDateA: string | undefined = readField('createdDate', a.node);
              const createdDateB: string | undefined = readField('createdDate', b.node);
              if (createdDateA == null || createdDateB == null) {
                return 0;
              }
              return createdDateB.localeCompare(createdDateA);
            });

            return {
              ...current,
              ...incoming,
              edges,
              pageInfo: variables?.after == null ? current.pageInfo : incoming.pageInfo,
            };
          },
        },
      },
      queryType: true,
    },
    Source: {
      fields: {
        applicableFilters: {
          merge(existing, incoming) {
            if (incoming !== undefined && incoming !== null) {
              return incoming;
            }
            return existing;
          },
        },
      },
    },
  },
});

export const resetCache = () => {
  cache.evict({
    broadcast: true,
  });

  cache.gc({
    resetResultCache: true,
    resetResultIdentities: true,
  });
};

const createClient = (token: string | null) =>
  new ApolloClient({
    cache: cache,
    credentials: 'include',
    defaultOptions,
    link: createApolloLink({ token }),
  });

export default createClient;
