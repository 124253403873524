import { Button, MenuItem, MenuList } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ADMIN_PANEL_URL, API_DOCS_URL, GITHUB_URL } from 'constants/urls';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { eggshell, midnight, theme } from 'theme/Theme';

import documentationLogo from '../../assets/navsection/documentation.svg';
import githubLogo from '../../assets/navsection/github-logo.svg';
import notifiAPLogo from '../../assets/navsection/notifi-ap.svg';

const menuItemsStyle = {
  '&.Mui-selected': {
    backgroundColor: eggshell,
  },
  '&.Mui-selected:hover': {
    backgroundColor: eggshell,
  },
  '&:hover': {
    backgroundColor: eggshell,
  },
  borderRadius: '4px',
  display: 'flex',
  fontFamily: 'RotaExtraBold',
  fontSize: '16px',
  gap: { sm: '8px', xs: '14px' },
  height: '40px',
  justifyContent: { sm: 'flex-start', xs: 'start' },
  margin: { sm: '0 12px 0 12px', xs: 0 },
  paddingLeft: '10px',
  px: { sm: 0, xs: 8 },
};

type Props = {
  setOpen: (open: boolean) => void;
};

const MENUS = [
  {
    href: API_DOCS_URL,
    imgSrc: documentationLogo,
    name: 'Documentation',
    target: '_blank',
  },
  {
    href: GITHUB_URL,
    imgSrc: githubLogo,
    name: 'GitHub',
    target: '_blank',
  },
  {
    href: ADMIN_PANEL_URL,
    imgSrc: notifiAPLogo,
    name: 'Notifi Admin Portal',
    target: '_blank',
  },
];

export const MenuListDevelopers: React.FC<React.PropsWithChildren<Props>> = () => {
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <MenuList
        sx={{
          borderRadius: '4px',
          fontFamily: 'RotaExtraBold',
          fontSize: '16px',
          height: { sm: '150px', xs: 'unset' },
          paddingBottom: 0,
          paddingTop: { sm: '12px', xs: '0' },
          pb: { sm: 0, xs: 2 },
          width: { sm: '220px', xs: 'unset' },
        }}
      >
        {MENUS.map(menu => (
          <a
            href={menu.href}
            key={menu.name}
            style={{ textDecoration: 'none' }}
            target={menu.target}
          >
            <MenuItem selected={!isMobile && location.pathname === menu.href} sx={menuItemsStyle}>
              <img alt={menu.name} src={menu.imgSrc} />
              <Button
                disableRipple
                sx={{
                  '&:hover': {
                    background: eggshell,
                  },
                  background: 'transparent !important',
                  borderRadius: 0,
                  color: midnight,
                  fontFamily: 'RotaExtraBold',
                  fontSize: '16px',
                  height: '100%',
                  justifyContent: { sm: 'flex-start', xs: 'center' },
                  mt: '5px',
                  position: 'relative',
                }}
              >
                {menu.name}
              </Button>
            </MenuItem>
          </a>
        ))}
      </MenuList>
    </>
  );
};
