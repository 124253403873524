import DOMPurify from 'dompurify';

export function isEmpty(str?: string | null): str is null | undefined {
  if (str == null || str === undefined || str.length === 0) {
    return true;
  }
  return false;
}

export function hasDaoPrefix(str?: string): boolean {
  if (isEmpty(str)) {
    return false;
  }

  const regex = new RegExp('__dao_');
  return regex.test(str ?? '');
}

export function addressEllipsis(str: string | undefined, index?: number): string | undefined {
  index = index || 4;

  if (!str || str.length < 11) {
    return str;
  }

  return str.slice(0, index) + '...' + str.slice(str.length - index);
}

/**
 * Sanitizes string input utility function
 * @param str input string
 * @returns trimmed and lowercased string
 */
export function sanitizeStringInput(str: string): string {
  return str.trim().toLowerCase();
}

export function sanitizeHTML(str: string): string {
  return DOMPurify.sanitize(str);
}

function titlecaseWord(word: string): string {
  if (word == null) return '';
  if (word.length <= 1) return word;

  return word[0].toUpperCase() + word.slice(1).toLowerCase();
}

// returns the titlecase of a word or sentence
export function titlecase(str: string): string {
  if (str == null) return '';
  const words = str.split(' ');
  return words.map(titlecaseWord).join(' ');
}

export const areSetsEqual = (a: string[], b: string[]): boolean => {
  const aSet = new Set(a);
  const bSet = new Set(b);

  if (aSet.size !== bSet.size) {
    return false;
  }

  return Array.from(bSet.keys()).every(bValue => aSet.has(bValue));
};

export const loadIpfsLink = (link: string): string => {
  if (!link || !link.includes('ipfs://')) return link;
  return link.replace('ipfs://', 'https://ipfs.io/ipfs/');
};
