import { Box, Container, Stack, Typography } from '@mui/material';
import { dusk, midnight } from 'theme/Theme';

import FastImplementationImg from '../../../assets/fast-implementation.png';
import GrowAudience from '../../../assets/grow-audience.png';

const EasySubscribeSection = () => {
  return (
    <Container
      maxWidth={false}
      sx={{
        maxWidth: 1250,
        px: 2,
        py: { sm: 9, xs: '40px' },
      }}
    >
      <Stack
        direction={{ md: 'row', xs: 'column' }}
        sx={{ alignItems: 'center', justifyContent: 'space-between', rowGap: '30px' }}
      >
        <Stack sx={{ maxWidth: '460px' }}>
          <Typography
            sx={{ color: midnight, fontFamily: 'RotaExtraBold', fontSize: '32px', mb: '16px' }}
          >
            Fast implementation
          </Typography>
          <Typography sx={{ color: dusk, fontFamily: 'RotaSemiBold', fontSize: '18px' }}>
            Implement email and Telegram alerts on your site within an hour. Configure your alerts
            in Notifi Admin and use Community Manager to start messaging your users.
          </Typography>
        </Stack>
        <Box sx={{ maxWidth: '430px' }}>
          <img
            alt='blockchains'
            src={FastImplementationImg}
            style={{ height: 'auto', width: '120%' }}
          />
        </Box>
      </Stack>

      <Stack
        direction={{ md: 'row-reverse', xs: 'column' }}
        sx={{ alignItems: 'center', justifyContent: 'space-between', pt: 2, rowGap: '30px' }}
      >
        <Stack sx={{ maxWidth: '460px' }}>
          <Typography
            sx={{ color: midnight, fontFamily: 'RotaExtraBold', fontSize: '32px', mb: '16px' }}
          >
            Grow your audience
          </Typography>
          <Typography sx={{ color: dusk, fontFamily: 'RotaSemiBold', fontSize: '18px' }}>
            Deliver 1:1 communications to your users at scale and manage all your off-chain and
            on-chain customer engagement through Notifi.
          </Typography>
        </Stack>
        <Box
          sx={{
            marginLeft: {
              lg: '-50px',
              xs: '-30px',
            },
            maxWidth: '425px',
          }}
        >
          <img
            alt='uptime'
            src={GrowAudience}
            style={{
              height: 'auto',
              width: '100%',
            }}
          />
        </Box>
      </Stack>
    </Container>
  );
};

export default EasySubscribeSection;
